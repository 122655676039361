import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_tenant } from '@public/stores/tenant_store'

function parse_task(task, task_groups) {
  const task_group = task_groups.find(el => el.id === task.task_group_id)
  return Object.freeze({
    ...task,
    start: Vue.$vl_time.parse_as_local(task.start).toJSDate(),
    end: Vue.$vl_time.parse_as_local(task.end).toJSDate(),
    bookable_from: task.bookable_from ? Vue.$vl_time.parse_as_local(task.bookable_from) : null,
    bookable_until: task.bookable_until ? Vue.$vl_time.parse_as_local(task.bookable_until) : null,
    allDay: task.is_all_day,
    title: task.name || task_group?.name,
    color: task_group?.color || '#123',
  })
}

export const use_public_calendar = defineStore('public_calendar', {
  state: () => ({
    calendar_events: [],
    task_group_ids_filter: [],
    task_groups: [],
    loaded_tasks_urls: [],
  }),

  actions: {
    add(tasks, task_groups) {
      // this method will only add tasks that have not been added yet
      const old_tasks = this.calendar_events
      const new_tasks = tasks.filter(t => !old_tasks.some(u => u.id === t.id)).map(task => parse_task(task, task_groups))
      this.calendar_events.push(...new_tasks)

      // populate task_groups for filters
      const old_task_groups = this.task_groups
      const new_task_groups = task_groups.filter(t => !old_task_groups.some(u => u.id === t.id))
      this.task_groups.push(...new_task_groups)
    },

    async load_all_events({ start, end }) {
      // this method will laod all events (courses and tasks) and write them into the store
      const urls = new Set()
      // this piece of logic will load all necessary months that come in via request
      for (let i = 0; start.plus({ months: i }).startOf('month') < end; i++) {
        const month = start.plus({ months: i })
        const url = `${use_tenant().api_home_url}/public/calendar/${month.year}/${month.month}`

        if (!this.loaded_tasks_urls.includes(url)) {
          this.loaded_tasks_urls.push(url)
          urls.add(url)
        }
      }

      if (urls.size > 0) {
        return Promise.all(
          [...urls].map(url =>
            Vue.smcb_axios.get(url).then(response => {
              const data = response.data

              // add flag to tasks to distinguish them from courses in the calendar
              const tasks = data.tasks.map(task => {
                return {
                  ...task,
                  is_task: true,
                }
              })

              // process and add all data to store
              this.add([...data.courses, ...tasks] || [], data.task_groups || [])
            })
          )
        )
          .then(() => {
            return this.calendar_events
          })
          .catch(error => {
            return error
          })
      } else {
        return Promise.resolve(this.calendar_events)
      }
    },

    write_task_groups_ids(ids) {
      this.task_group_ids_filter = [...ids].sort()
    },

    activate_task_groups({ task_group_ids, router }) {
      let ids = [...task_group_ids].sort()
      this.write_task_groups_ids(ids)
      ids = ids.join(',') || null
      router.replace({ params: { task_group_ids: ids } })
    },
  },

  getters: {
    filtered_tasks(state) {
      // if the filter array has no values, return all events, otherwise return
      // only the event which task_group_id is in the filter array
      if (state.task_group_ids_filter.length === 0) return state.calendar_events
      return state.calendar_events.filter(event => state.task_group_ids_filter.includes(event.task_group_id))
    },
  },
})
