<template>
  <div class="dropdown">
    <a id="dropdown-menu-button" class="cursor-pointer" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span class="mr-1">{{ name_of_current || $t('sidemenu.language') }}</span>
      <vl-icon size="lg" icon="arrow_down" />
    </a>
    <div class="dropdown-menu mt-3 vl-dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-menu-button" style="z-index: 10000">
      <div v-for="(name, code) in languages" :key="code" class="dropdown-item" :class="{ selected: code === $i18n.locale }" @click="switch_language(code)">
        {{ name }}
      </div>
    </div>
  </div>
</template>
<script>
import VlIcon from '@core/shared/components/vl_icon.vue'

const language_db = {
  en: 'English',
  de: 'Deutsch',
  it: 'Italiano',
  ja: '日本語',
  nl: 'Nederlands',
  es: 'Español',
  fr: 'Français',
  cs: 'Česky', // Czech
  tr: 'Türkçe', // Turkish
  pl: 'Polski', // Polish
  ro: 'Limba română', // Romanian
  sk: 'Slovenčina', // Slovak
  sl: 'Slovenščina', // Slovene
  uk: 'Українська', // Ukrainian
  ru: 'Русский', // Russian
  // 'bg': 'Български (Bulgarian)',
  // 'hu': 'Magyar (Hungarian)',
  // 'lt': 'Lietuvių', // Lithuanian
  // 'lv': 'Latviešu', // Latvian
  // 'sq': 'Shqip', // Albanian
  // 'sr': 'Српски', // Serbian
}

const language_db_short = {
  en: 'EN',
  de: 'DE',
  it: 'IT',
  ja: '日本語',
  nl: 'NL',
  es: 'ES',
  fr: 'FR',
  cs: 'CS', // Czech
  tr: 'TR', // Turkish
  pl: 'PL', // Polish
  ro: 'RO', // Romanian
  sk: 'SK', // Slovak
  sl: 'SL', // Slovene
  uk: 'UK', // Ukrainian
  ru: 'RU', // Russian
  // 'bg': 'Български (Bulgarian)',
  // 'hu': 'Magyar (Hungarian)',
  // 'lt': 'Lietuvių', // Lithuanian
  // 'lv': 'Latviešu', // Latvian
  // 'sq': 'Shqip', // Albanian
  // 'sr': 'Српски', // Serbian
}

export default {
  computed: {
    languages() {
      return language_db
    },

    name_of_current() {
      return language_db_short[this.$i18n.locale]
    },
  },

  methods: {
    switch_language(code) {
      if (this.$i18n.locale === code) return
      const origin_url = window.location
      let origin_path = origin_url.pathname
      origin_path = origin_path.replace(`/${this.$i18n.locale}`, '')
      origin_path = `/${code}` + origin_path
      const destination_url = origin_url.origin + origin_path + origin_url.search + origin_url.hash
      window.open(destination_url, '_self')
    },
  },

  components: {
    VlIcon,
  },
}
</script>

<style lang="scss" scoped>
.dropdown,
.dropdown * {
  color: var(--neutral-black);
}

#dropdown-menu-button {
  white-space: nowrap;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item.selected {
  cursor: default;
  background-color: var(--plum-50);
}
</style>
